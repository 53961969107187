/* .profile-main-div {
    color: white;
}

.user-profile-card {
    margin: 5px 0px;
}

.user-profile {
    display: flex;
}

.user-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.user-name {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.user-name p {
    color: white;
}

.about-card {
    margin: 5px 0px;
}

.about .heading {
    color: white;
    margin-bottom: 20px;
} */

.about .profile-info {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 70%;
  row-gap: 10px;
}

.about .profile-info .label-name {
  color: gray;
  font-size: 14px;
  font-weight: bold;
}

.profile-information-card {
  margin: 5px 0px;
}

.profile-information .heading {
  color: white;
}

.profile-information .profile-info {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 60%;
  row-gap: 10px;
}

.profile-information .profile-info .label-name {
  color: gray;
  font-size: 14px;
  font-weight: bold;
}

.email-notification {
  color: white;
}

.email-checkbox .MuiFormControlLabel-label {
  color: white !important;
}

.change-password-card {
  margin: 5px 0px;
}

.change-password .heading {
  color: white;
}

.change-password-button {
  margin-top: 10px;
}

.data-name {
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.edit-div {
  display: flex;
}

.edit-div button {
  font-size: 12px;
  color: #4318ff;
  padding-top: 3px;
}

.edit-div button:hover {
  color: #4318ff !important;
  background-color: transparent;
  transform: none !important;
}

.save-div {
  display: flex;
  align-items: center;
}

.save-div button {
  font-size: 12px;
  color: #4318ff !important;
  padding-top: 3px;
}

.save-div button:hover {
  color: #4318ff !important;
  background-color: transparent;
  transform: none !important;
}
