/* .custom-table div table tbody tr td{
    padding: 5px 3px;
} */
/* 
.custom-table div table {
    padding: 10px 10px;
} */

/* .custom-table {
    /* margin: 0px 17px; */
/* width: 100%; */
/* margin-top: 20px; */
/* } */
*/

/* .custom-table tbody tr td div.MuiBox-root {
    width: auto;
    width: 160px;

} */

/* .custom-table tbody tr:last-child td div.MuiBox-root {
    width: auto;
    width: 170px;

} */

/* .custom-table thead tr th div.MuiBox-root {
    opacity: 1;
    font-size: 0.75rem;
}

.custom-table div.MuiTableContainer-root {
    overflow-x: unset;
}

.custom-table-data {
    margin: 0px 3px;
}  */

.clear {
  cursor: pointer;
  font-size: 14px !important;
}

.download-preview-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.upload-clear {
  margin-bottom: 10px;
}

.custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.shipper {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

.shipper-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.shipper-reference-second {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.carrier-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.unique-consignment-reference {
  grid-column-start: 3;
  grid-column-end: 5;
}

.consignee {
  grid-column-start: 1;
  grid-column-end: 3;
}

.carrier-name {
  grid-column-start: 3;
  grid-column-end: 5;
}

.notify-party {
  grid-column-start: 1;
  grid-column-end: 3;
}

.additional-notify-party {
  grid-column-start: 3;
  grid-column-end: 5;
}

.pre-carriage-by {
  grid-column-start: 1;
  grid-column-end: 2;
}

.place-of-receipt {
  grid-column-start: 2;
  grid-column-end: 3;
}

.additional-information {
  grid-column-start: 3;
  grid-column-end: 5;
}

.data-table {
  grid-column-start: 1;
  grid-column-end: 5;
}

.datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.datatable td div.MuiBox-root {
  width: 10rem;
}

.datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.transport-voyageno {
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  justify-content: space-between;
}

.transport {
  width: 66%;
}

.voyageno {
  width: 30%;
}

.place-of-delivery {
  grid-column-start: 2;
  grid-column-end: 3;
}

.port-of-loading {
  grid-column-start: 3;
  grid-column-end: 4;
}

.port-of-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
}

.netWeight-grossWeight-volume {
  grid-column-start: 1;
  grid-column-end: 5;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.additional-information-second {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 14;
}

.signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
}

.authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
}

.add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.add-shipper label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.add-shipper label svg {
  width: 22px;
  height: 22px;
}

.signatureCanvas {
  background-color: #0f1535 !important;

  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.delete-icon-style {
  cursor: pointer;
}
