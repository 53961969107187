/* .custom-table div table tbody tr td {
    padding: 5px 3px;
  }
  
  .custom-table div table {
    padding: 10px 10px;
  }
  
  .custom-table {
    margin: 0px 17px;
    width: 100%;
    margin-top: 20px;
  }
  
  .custom-table tbody tr td div.MuiBox-root {
    width: auto;
    min-width: 160px;
  }
  
  .custom-table tbody tr:last-child td div.MuiBox-root {
    width: auto;
    min-width: 90px;
  }
  
  .custom-table thead tr th div.MuiBox-root {
    opacity: 1;
    font-size: 0.75rem;
  }
  
  .custom-table div.MuiTableContainer-root {
    overflow-x: unset;
  }
  
  .custom-table-data {
    margin: 0px 3px;
  } */

/* .certificate-datatable .MuiTableContainer-root {
  overflow: visible;
} */

/* .certificate-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.certificate-datatable td div.MuiBox-root {
  width: 9rem;
}

.certificate-datatable td:nth-child(1) div.MuiBox-root {
  width: 10rem;
}

.certificate-datatable td:nth-child(4) div.MuiBox-root {
  width: 14rem;
} */

.certificate-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.certificate-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.certificate-upload-clear {
  margin-bottom: 10px;
}

.certificate-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.certificate-exporter {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.certificate-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.certificate-invoice-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.certificate-invoice-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

/* .com-invo-bill-of-lading-number {
  grid-column-start: 3;
  grid-row-start: 3;
} */
.certificate-letter-of-credit-no {
  grid-column-start: 3;
  grid-row-start: 3;
}

/* .com-invo-reference {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
} */

/* .com-invo-buyer-reference {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
} */

.certificate-consignee {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
}

.certificate-buyer-if-not-consignee {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 4;
}

.certificate-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
}

.certificate-type {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
}

.certificate-packInfo {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 9;
}

/* .com-invo-country-of-goods {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
} */

.certificate-transport {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
  grid-row-end: 6;
}

.certificate-voyage-no {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 6;
  grid-row-end: 6;
}

/* .com-invo-country-of-final-destination {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 7;
} */

.certificate-port-of-loading {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 7;
}

.certificate-port-of-discharge {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 7;
}

/* .com-invo-term-of-payment {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 8;
} */

/* .com-invo-place-of-origin {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 8;
} */

.certificate-date-of-departure {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 8;
}

.certificate-final-destination {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 8;
}

/* .com-invo-marine-cover-policy-no {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 9;
} */

/* .com-invo-letter-of-credit-no {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 9;
} */

.certificate-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 9;
}

.certificate-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 10;
}

.certificate-declaration1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 11;
}

.certificate-declaration2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.certificate-produced {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 12;
}

/* .certificate-additional-information {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 11;
  grid-row-end: 15;
} */

.certificate-place-of-issue {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 13;
}

.certificate-date {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 13;
}

.certificate-place-of-issue2 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 13;
}

.certificate-date2 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 13;
}

.certificate-signatory-company {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 14;
  /* grid-row-end: 14; */
}

.certificate-signatory-company2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 14;
  /* grid-row-end: 14; */
}

.certificate-authorized-Signatory {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 15;
  /* grid-row-end: 13; */
}

.certificate-authorized-Signatory2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 15;
  /* grid-row-end: 13; */
}

.certificate-signature {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 16;
}
.certificate-signature2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 16;
}

/* .certificate-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 14;
  grid-row-end: 16;
} */

.certificate-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.certificate-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.certificate-add-exporter label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.certificate-add-exporter label svg {
  width: 22px;
  height: 22px;
}

.certificate-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.certificate-datatable td div.MuiBox-root {
  width: 10rem;
}

.certificate-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.certificate-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}
.certificate-delete-icon-style {
  cursor: pointer;
}
