/*.custom-table div table tbody tr td {
  padding: 5px 3px;
}*/

/*.custom-table div table {
  padding: 10px 10px;
}*/

/*.custom-table {
   margin: 0px 17px; 
   width: 100%;
   margin-top: 20px; 
}*/

/*.custom-table tbody tr td div.MuiBox-root {
  width: auto;
  min-width: 160px;
}*/

/*.custom-table tbody tr:last-child td div.MuiBox-root {
  width: auto;
  min-width: 90px;
}*/

/*.custom-table thead tr th div.MuiBox-root {
  opacity: 1;
  font-size: 0.75rem;
}*/

/*.custom-table div.MuiTableContainer-root {
  overflow-x: unset;
}*/

/*.custom-table-data {
  margin: 0px 3px;
}*/

.inv-datatable .MuiTableContainer-root {
  overflow: visible;
}

.inv-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.inv-datatable td div.MuiBox-root {
  width: 9rem;
}

/* .inv-datatable td:last-child div.MuiBox-root {
  width: 1rem;
} */

.inv-datatable td:nth-child(1) div.MuiBox-root {
  width: 10rem;
}

/* .inv-datatable td:nth-child(2) div.MuiBox-root {
  width: 10rem;
} */

.inv-datatable td:nth-child(4) div.MuiBox-root {
  width: 14rem;
}

.inv-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.inv-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.inv-upload-clear {
  margin-bottom: 10px;
}

.inv-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.inv-from {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.inv-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.inv-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.inv-date1 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.inv-buyer {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
}

.inv-to {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
}

.inv-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
}

.inv-type-of-shipment {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
}

.inv-port-loading {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.inv-port-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
}

.inv-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
}

.inv-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
}

.inv-additional-inform {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 11;
}

.inv-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
}

.inv-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 7;
}

.inv-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 8;
}

.inv-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 9;
}

.inv-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.inv-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.inv-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 12;
}

.inv-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.inv-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.inv-add-from label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.inv-add-from label svg {
  width: 22px;
  height: 22px;
}

.inv-delete-icon-style {
  cursor: pointer;
}
